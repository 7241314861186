@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: inter , 'sans-serif';
}


@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;  
        scrollbar-width: none;  
  }
}